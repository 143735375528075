import React from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { CONVERSATION_STATUS } from "../../MailBoxTable";
import moment from "moment";
import { useNavigate } from "react-router";

const InboxRow = ({
  id,
  languagePair,
  type,
  timestamps,
  consultantStatus,
  user,
  name,
}) => {
  const navigate = useNavigate();

  const getStatusStyles = (status) => {
    switch (status) {
      case CONVERSATION_STATUS.NEW:
        return "status status-new";
      case CONVERSATION_STATUS.COMPLETED:
        return "status status-completed";
      case CONVERSATION_STATUS.NEW_IN_CONVERSATION:
        return "status status-new_in_conversation";
      case CONVERSATION_STATUS.SEEN:
        return "status status-seen";
      default:
        return null;
    }
  };

  const getTypeText = (type) => {
    switch (type) {
      case "question":
        return "Zapytaj eksperta";
      case "activity":
        return "Praca domowa";
      default:
        return "";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case CONVERSATION_STATUS.NEW:
        return "Nowy";
      case CONVERSATION_STATUS.COMPLETED:
        return "Zakończony";
      case CONVERSATION_STATUS.NEW_IN_CONVERSATION:
        return "Nowa wiadomość";
      case CONVERSATION_STATUS.SEEN:
        return "Odczytany";
      default:
        return null;
    }
  };

  return (
    <TableRow
      hover
      onMouseUp={(event) => {
        if (event.metaKey || event.ctrlKey || event.button === 1) {
          event.preventDefault();
          const searchParams = new URLSearchParams(window.location.search);
          const qs = searchParams.toString();
          const win = window.open(
            `/consultant/mail-box/conversation/${id}?${qs}`,
            "_blank"
          );
          win?.focus();
        } else {
          navigate(`/consultant/mail-box/conversation/${id}`);
        }
      }}
    >
      <TableCell>{languagePair.languageFrom.languageShort}</TableCell>
      <TableCell>{languagePair.languageTo.languageShort}</TableCell>
      <TableCell>
        <Typography variant="body1" sx={{ fontSize: "12px" }}>
          {user.username}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: "12px" }}
        >
          <b>{getTypeText(type)}:</b> {name}
        </Typography>
      </TableCell>
      <TableCell>{moment(timestamps.createdAt).format("DD-MM")}</TableCell>
      <TableCell>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: '5px'
          }}
        >
          <div
            className={getStatusStyles(consultantStatus)}
            style={{ width: "15px", height: "15px", borderRadius: "5px" }}
          />
          <div style={{ fontSize: "12px" }}>
            {getStatusText(consultantStatus)}
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default InboxRow;
