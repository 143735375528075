import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import MainModal from "../../../../../../../platform/components/Modals/MainModal/MainModal";
import { CONVERSATION_STATUS } from "../../../MailBoxTable";

const StatusModal = ({ isModalOpen, setIsModalOpen, setFilterField }) => {
  const [selectedItem, setSelectedItem] = useState([]);

  const onSubmit = () => {
    setFilterField("status",selectedItem);
    setIsModalOpen(false)
  };

  return (
    <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
      <Box width={250}  mx="auto" >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Status</Typography>
        </Box>
        <Autocomplete
                  id="type-select"
                  options={[
                    {
                      label: "Wszystkie",
                      value: undefined,
                    },
                    {
                      label: "Nowy",
                      value: CONVERSATION_STATUS.NEW,
                    },
                    {
                      label: "Zakończony",
                      value: CONVERSATION_STATUS.COMPLETED,
                    },
                    {
                      label: "Nowa wiadomość",
                      value: CONVERSATION_STATUS.NEW_IN_CONVERSATION,
                    },
                    {
                      label: "Odczytany",
                      value: CONVERSATION_STATUS.SEEN,
                    },
                  ]}
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  onChange={(_, newValue) => {
                    setSelectedItem(newValue?.value);
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
          >
            Zastosuj
          </Button>
        </Box>
      </Box>
    </MainModal>
  );
};

export default StatusModal;
