
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import api from "../../api/api";
import { downloadBase64File } from "../../common/functions/downloadBase64File";
import Store from "../../../Store";


export const DownloadCertificatePage = () => {
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const download = () => {
    api.get(`/certificate?token=${token}`).then((certRes) => {
        downloadBase64File(certRes.data, `certificate.pdf`);
        navigate("/");
      });
  }

  useEffect(() => {
    download();
  }, [token, navigate]);

  // Style objects
  const containerStyle = {
    border: "1px solid #d4d4d4",
    padding: "20px",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    marginBottom: "20px",
  };

  const iconContainerStyle = {
    fontSize: "24px",
    color: "#4CAF50",
    marginBottom: "10px",
  };

  const linkStyle = {
    color: "#4CAF50",
    textDecoration: "none",
  };

  const footerContainerStyle = {
    borderTop: "1px solid #e0e0e0",
    paddingTop: "20px",
    fontFamily: "Arial, sans-serif",
  };

  const footerHeaderStyle = {
    textAlign: "center",
    color: "#333",
  };

  const listStyle = {
    maxWidth: "600px",
    margin: "0 auto",
    textAlign: "left",
    color: "#555",
    lineHeight: "1.6",
  };

  return (
    <div>
      <div style={containerStyle}>
        <div style={iconContainerStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#4CAF50"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M13 5h-2v6H8l4 4 4-4h-3zM12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0z" />
          </svg>
        </div>
        <h3>{Store.getText('downloadMessage')}</h3>
        <p>
          {Store.getText('downloadHelp')}{" "}
          <a href="#" style={linkStyle} onClick={download}>
            {Store.getText('clickHere')}
          </a>
        </p>
      </div>

      <div style={footerContainerStyle}>
        <h4 style={footerHeaderStyle}>
          {Store.getText('certificateBenefitsHeader')}
        </h4>
        <ul style={listStyle}>
          <li>
            <strong>{Store.getText('benefitConfirmationOfSkillsPart1')}</strong>{" "}
            {Store.getText('benefitConfirmationOfSkills')}
          </li>
          <li>
            <strong>{Store.getText('benefitIncreasedCompetitivenessPart1')}</strong>{" "}
            {Store.getText('benefitIncreasedCompetitiveness')}
          </li>
          <li>
            <strong>{Store.getText('benefitPersonalDevelopmentPart1')}</strong>{" "}
            {Store.getText('benefitPersonalDevelopment')}
          </li>
        </ul>
      </div>
    </div>
  );
};