import React, { useCallback, useEffect, useRef, useState } from 'react';
import './MailBox.css';
import MailBoxTable from './components/MailBoxTable';
import BoxElement from './components/BoxElement';
import api from '../../../platform/api/api';
import { Route, Routes } from 'react-router';
const queryString = require('query-string');

const MIN_EMAIL_LENGTH = 3;

const PER_PAGE = 50;

const Pagination = ({ offset, limit, allCount, currentPage, changePage }) => {
    return (
        <div className="consultant-mailbox-pagination-wrapper">
            {' '}
            <button
                disabled={offset <= 0}
                onClick={() => changePage(currentPage - 1)}
                className="consultant-mailbox-button"
            >
                {'<'}
                </button>
            <div>
                <p>
                    <b>{currentPage}</b> z {Math.ceil(allCount / limit)}
                </p>
            </div>
            <button
                disabled={offset + limit >= allCount}
                onClick={() => changePage(currentPage + 1)}
                className="consultant-mailbox-button"
            >
                {'>'}
            </button>
            <div className='consultant-mailbox-pagination-count'>
                Ilość {allCount}
            </div>
        </div>
    );
};

const MailBox = () => {
    const [data, setData] = useState([]);
    const [conversation, setConversation] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [conversations, setConversations] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterValues, setFilterValues] = useState({});

    const fetchMailboxData = useCallback(() => {
        const filterObj = {...filters}
        if(filterObj.date && (!filterObj.date.from || !filterObj.date.to)) {
            delete filterObj.date;
        }
        const qsObj = {
            offset: (currentPage - 1) * PER_PAGE,
            limit: PER_PAGE,
            filters: JSON.stringify(filterObj),
        };
        api.get(
            `/conversations/consultant?${queryString.stringify(qsObj)}`
        ).then((response) => {
            setData(response.data);
            setConversations(response.data.conversations);
        });
    }, [currentPage, filters]);

    const fetchFilterValues = useCallback(() => {
        api.get(`/conversations/consultant/filters`).then((response) => {
            const {data} = response
            data.markets = data.markets.sort((a, b) => a.localeCompare(b))
            setFilterValues(data);
        });
    }, [currentPage, filters]);

    useEffect(() => {
        if (!conversation) fetchMailboxData();
    }, [fetchMailboxData]);

    useEffect(() => {
        if (!conversation) fetchFilterValues();
    }, [fetchFilterValues]);

    const changePage = async (value) => {
        setCurrentPage(value);
    };

    return (
        <div className="mailbox-wrapper">
            <div className="mailbox-wrapper__top-header">
                <h3 className="mailbox-title">Skrzynka odbiorcza</h3>
            </div>


            <Routes>
                <Route
                    path="conversation/:conversationId"
                    element={<BoxElement />}
                ></Route>
                <Route
                    exact
                    index
                    element={
                        <>
                            <MailBoxTable
                                data={conversations}
                                filters={filters}
                                setFilters={setFilters}
                                filterValues={filterValues}
                                fetchMailboxData={fetchMailboxData}
                            />
                            <Pagination
                                offset={(currentPage - 1) * PER_PAGE}
                                limit={PER_PAGE}
                                allCount={data.count}
                                changePage={changePage}
                                currentPage={currentPage}
                            />
                        </>
                    }
                ></Route>
            </Routes>
        </div>
    );
};

export default MailBox;
