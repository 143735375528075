import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, InputBase, IconButton } from '@mui/material';
import { Search as SearchIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import FilterModal from '../Modals/FilterModal/FilterModal';

const InboxHeader = ({ data, setFilters, filterValues }) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);


  const setFilterField = (fieldName, value) => {
    setFilters((prev) => ({ ...prev, [fieldName]: value }));
  };

  return (
    <AppBar position="static" color="default">
            <FilterModal
        setFilterField={setFilterField}
        setFilters={setFilters}
        filterValues={filterValues}
        isModalOpen={isFilterModalOpen}
        setIsModalOpen={setIsFilterModalOpen}
      />
      <Toolbar style={{display: 'flex', justifyContent: 'flex-end'}}>

        {/* <div style={{ position: 'relative', marginRight: 16 }}>
          <SearchIcon style={{ position: 'absolute', left: "5px", top: '50%', transform: 'translateY(-50%)' }} />
          <InputBase
            placeholder="Szukaj"
            style={{
              paddingLeft: 24,
              width: 200,
              border: '1px solid #ccc',
              borderRadius: 4,
            }}
          />
        </div> */}
        <IconButton style={{transform: 'scale(2)', marginRight: "10px"}} onClick={() => setIsFilterModalOpen(true)}>
          <FilterListIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default InboxHeader;