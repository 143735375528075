import React, { useRef, useState } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";
import MainModal from "../../../../../../../platform/components/Modals/MainModal/MainModal";

const FilterModal = ({
  isModalOpen,
  setIsModalOpen,
  setFilterField,
  filterValues,
}) => {
  const [user, setUser] = useState();
  const [topic, setTopic] = useState();
  const [type, setType] = useState();

  const autoC1 = useRef(null);
  const autoC2 = useRef(null);
  const autoC3 = useRef(null);

  const handleReset = () => {
    [autoC1, autoC2, autoC3].forEach((autoC) => {
      const ele = autoC.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    });
  };

  const onSubmit = () => {
    setFilterField("username", user);
    setFilterField("name", topic);
    setFilterField("type", type);

    setIsModalOpen(false);
  };

  return (
    <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
      <Box width={300} mx="auto">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Filtry</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mb={2}
          gap={3}
        >
          <Autocomplete
            id="user-select"
            options={filterValues.usernames}
            ref={autoC1}
            onChange={(_, newValue) => {
              setUser(newValue);
            }}
            autoHighlight
            renderOption={(props, option) => (
              <Box style={{ fontSize: 14 }} {...props}>
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Użytkownik"
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Autocomplete
            id="name-select"
            options={filterValues.names}
            ref={autoC2}
            onChange={(_, newValue) => {
              setTopic(newValue);
            }}
            renderOption={(props, option) => (
              <Box style={{ fontSize: 14 }} {...props}>
                {option}
              </Box>
            )}
            autoHighlight
            renderInput={(params) => (
              <TextField
                {...params}
                label="Temat"
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <Autocomplete
            id="type-select"
            ref={autoC3}
            options={[
              {
                label: "Zapytaj experta",
                value: "question",
              },
              { label: "Zadanie", value: "activity" },
            ]}
            onChange={(_, newValue) => {
              setType(newValue?.value);
            }}
            autoHighlight
            renderOption={(props, option) => (
              <Box style={{ fontSize: 14 }} {...props}>
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Zadanie"
                variant="standard"
                size="small"
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: "15px" },
                }}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Button variant="text" color="primary" onClick={handleReset}>
            Wyczyść
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
          >
            Zastosuj
          </Button>
        </Box>
      </Box>
    </MainModal>
  );
};

export default FilterModal;
