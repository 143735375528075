import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Autocomplete,
} from "@mui/material";
import MainModal from "../../../../../../../platform/components/Modals/MainModal/MainModal";

const MarketModal = ({
  isModalOpen,
  setIsModalOpen,
  setFilterField,
  filterValues,
}) => {
  const [selectedItem, setSelectedItem] = useState([]);

  const onSubmit = () => {
    setFilterField("market", selectedItem);
    setIsModalOpen(false);
  };

  return (
    <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
      <Box width={300} mx="auto">
        <Typography variant="h6">Rynek</Typography>
        <Autocomplete
          id="market-select"
          options={filterValues.markets}
          multiple
          freeSolo
          onChange={(_, newValue) => {
            setSelectedItem(newValue);
          }}
          autoHighlight
          renderOption={(props, option) => (
            <Box style={{ fontSize: 14 }} {...props}>
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rynek"
              variant="standard"
              size="small"
              inputProps={{
                ...params.inputProps,
                style: { fontSize: "15px" },
              }}
            />
          )}
        />

        <Button
          variant="contained"
          fullWidth
          color="primary"
          style={{ marginTop: 16 }}
          onClick={onSubmit}
        >
          Zastosuj
        </Button>
      </Box>
    </MainModal>
  );
};

export default MarketModal;
