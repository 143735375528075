import React, { useState } from "react";
import { Box, TextField, Button, Typography, IconButton } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import {
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import MainModal from "../../../../../../../platform/components/Modals/MainModal/MainModal";
import moment from "moment";

const DateModal = ({ isModalOpen, setIsModalOpen, setFilters }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onSubmut = () => {
    setFilters((prev) => {
      prev.date = {
        ...prev.date,
      };
      prev.date.from = moment(startDate).startOf("day").toISOString();
      prev.date.to = moment(endDate).startOf("day").toISOString();
      return { ...prev };
    });

    setIsModalOpen(false)
  }

  return (
    <MainModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          display="flex"
          flexDirection="column"
          width={300}
          mx="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Data</Typography>
          </Box>
          <Box display="flex" flexDirection="column" mb={2} gap={2}>
            <DatePicker
              label="Od:"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <CalendarTodayIcon style={{ marginLeft: 8 }} />
                    ),
                  }}
                />
              )}
            />
            <DatePicker
              label="Do:"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <CalendarTodayIcon style={{ marginLeft: 8 }} />
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box display="flex" justifyContent="center" mb={2}>
            <Button variant="contained" color="primary" onClick={onSubmut}>
              Zastosuj
            </Button>
          </Box>
        </Box>
      </LocalizationProvider>
    </MainModal>
  );
};

export default DateModal;
