import { Autocomplete, TextField, Box } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate } from "react-router";
import Inbox from "./mobile/MailboxTable/Inbox";

export const CONVERSATION_STATUS = {
  NEW: "New",
  COMPLETED: "Completed",
  NEW_IN_CONVERSATION: "New in conversation",
  SEEN: "Seen",
};

const getTypeText = ({ type }) => {
  switch (type) {
    case "question":
      return "Zapytaj eksperta";
    case "activity":
      return "Praca domowa";
    default:
      return "";
  }
};

const MailBoxTable = ({ data, setFilters, filterValues, fetchMailboxData }) => {
  const navigate = useNavigate();
  const getStatusStyle = (status) => {
    switch (status) {
      case CONVERSATION_STATUS.NEW:
        return "status status-new";
      case CONVERSATION_STATUS.COMPLETED:
        return "status status-completed";
      case CONVERSATION_STATUS.NEW_IN_CONVERSATION:
        return "status status-new_in_conversation";
      case CONVERSATION_STATUS.SEEN:
        return "status status-seen";
      default:
        return null;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case CONVERSATION_STATUS.NEW:
        return "Nowy";
      case CONVERSATION_STATUS.COMPLETED:
        return "Zakończony";
      case CONVERSATION_STATUS.NEW_IN_CONVERSATION:
        return "Nowa wiadomość";
      case CONVERSATION_STATUS.SEEN:
        return "Odczytany";
      default:
        return null;
    }
  };

  const setFilterField = (fieldName, value) => {
    setFilters((prev) => ({ ...prev, [fieldName]: value }));
  };

  const setFilterDate = (type, value) => {
    setFilters((prev) => {
      prev.date = {
        ...prev.date,
      };
      prev.date[type] = value;
      return { ...prev };
    });
  };

  useEffect(() => fetchMailboxData(), []);

  if(window.innerWidth < 768) return <Inbox data={data} setFilters={setFilters} filterValues={filterValues} fetchMailboxData={fetchMailboxData}/>

  return (
    <div className="mailbox-table">
      <div className="mailbox-table__style">
        <table cellSpacing="0">
          <thead className="mailbox-thead">
            <tr style={{ width: "100%" }}>
              <th>
                <Autocomplete
                  id="market-select"
                  options={filterValues.markets}
                  multiple
                  freeSolo
                  onChange={(_, newValue) => {
                    setFilterField("market", newValue);
                  }}
                  autoHighlight
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Rynek"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
              </th>
              <th>
                <Autocomplete
                  id="market-select"
                  options={filterValues.languages}
                  multiple
                  freeSolo
                  onChange={(_, newValue) => {
                    setFilterField("language", newValue);
                  }}
                  autoHighlight
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Język"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
              </th>
              <th>
                <Autocomplete
                  id="user-select"
                  options={filterValues.usernames}
                  onChange={(_, newValue) => {
                    setFilterField("username", newValue);
                  }}
                  autoHighlight
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Użytkownik"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
              </th>
              <th>
                <Autocomplete
                  id="type-select"
                  options={[
                    {
                      label: "Zapytaj experta",
                      value: "question",
                    },
                    { label: "Zadanie", value: "activity" },
                  ]}
                  onChange={(_, newValue) => {
                    setFilterField("type", newValue?.value);
                  }}
                  autoHighlight
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Zadanie"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
              </th>
              <th>
                <Autocomplete
                  id="name-select"
                  options={filterValues.names}
                  onChange={(_, newValue) => {
                    setFilterField("name", newValue);
                  }}
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option}
                    </Box>
                  )}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Temat"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
              </th>
              <th>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div style={{display: 'flex', flexDirection: 'column', padding: '5px 0'}}>
                  <DatePicker
                    label="Data od"
                    slotProps={{
                      textField: { size: "small" },
                      field: { clearable: true },
                    }}
                    onChange={(momentDate) => {
                      setFilterDate(
                        "from",
                        momentDate
                          ? momentDate.startOf("day").toISOString()
                          : undefined
                      );
                    }}
                  />
                  <DatePicker
                    label="Data do"
                    slotProps={{
                      textField: { size: "small" },
                      field: { clearable: true },
                    }}
                    onChange={(momentDate) => {
                      setFilterDate(
                        "to",
                        momentDate
                          ? momentDate.endOf("day").toISOString()
                          : undefined
                      );
                    }}
                  />
                  </div>
                </LocalizationProvider>
              </th>
              <th>
                <Autocomplete
                  id="type-select"
                  options={[
                    {
                      label: "Nowy",
                      value: CONVERSATION_STATUS.NEW,
                    },
                    {
                      label: "Zakończony",
                      value: CONVERSATION_STATUS.COMPLETED,
                    },
                    {
                      label: "Nowa wiadomość",
                      value: CONVERSATION_STATUS.NEW_IN_CONVERSATION,
                    },
                    {
                      label: "Odczytany",
                      value: CONVERSATION_STATUS.SEEN,
                    },
                  ]}
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 14 }} {...props}>
                      {option.label}
                    </Box>
                  )}
                  onChange={(_, newValue) => {
                    setFilterField("status", newValue?.value);
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="standard"
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "15px" },
                      }}
                    />
                  )}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((element) => (
                <tr
                  key={element.topic + "" + element.username}
                  onMouseUp={(event) => {
                    if (event.metaKey || event.ctrlKey || event.button === 1) {
                      event.preventDefault()
                      const searchParams = new URLSearchParams(window.location.search);
                      const qs = searchParams.toString()
                      const win = window.open(
                        `/consultant/mail-box/conversation/${element.id}?${qs}`,
                        "_blank"
                      );
                      win?.focus();
                    } else {
                      navigate(
                        `/consultant/mail-box/conversation/${element.id}`
                      );
                    }
                  }}
                >
                  <td>
                    {element.languagePair.languageFrom.languageShort}
                  </td>
                  <td>
                    {element.languagePair.languageTo.languageShort}
                  </td>
                  <td>{element.user.username}</td>
                  <td>
                    {element.type === "question"
                      ? "Zapytaj ekperta"
                      : "Praca domowa"}
                  </td>
                  <td>
                    <b>{getTypeText(element)}:</b> {element.name} (Lekcja:{" "}
                    {element.lesson.number}.{element.lesson.subNumber} Język:{" "}
                    {element.languagePair.languageFrom.languageShort}-
                    {element.languagePair.languageTo.languageShort})
                  </td>

                  <td>
                    {moment(element.timestamps.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    <span className={getStatusStyle(element.consultantStatus)}>
                      {getStatusText(element.consultantStatus)}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MailBoxTable;
