import React from "react";
import { Container } from "@mui/material";
import InboxHeader from "./InboxHeader";
import InboxTable from "./InboxTable";

const Inbox = ({ data, setFilters, filterValues }) => {
  return (
    <div>
      <InboxHeader
        data={data}
        setFilters={setFilters}
        filterValues={filterValues}
      />
      <Container maxWidth="md">
        <InboxTable
          data={data}
          setFilters={setFilters}
          filterValues={filterValues}
        />
      </Container>
    </div>
  );
};

export default Inbox;
