import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import InboxRow from "./InboxRow";
import MarketModal from "../Modals/MarketModal/MarketModal";
import LanguageModal from "../Modals/LanguageModal/LanguageModal";
import DateModal from "../Modals/DateModal/DateModal";
import StatusModal from "../Modals/StatusModal/StatusModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          padding: '15px 8px'
        },
      },
    },
  },
});

const InboxTable = ({ data, setFilters, filterValues }) => {
  const [isMarketModalOpen, setIsMarketModalOpen] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const setFilterField = (fieldName, value) => {
    setFilters((prev) => ({ ...prev, [fieldName]: value }));
  };

  const setFilterDate = (type, value) => {
    setFilters((prev) => {
      prev.date = {
        ...prev.date,
      };
      prev.date[type] = value;
      return { ...prev };
    });
  };

  return (
    <div>
      <MarketModal
        setFilterField={setFilterField}
        setFilterDate={setFilterDate}
        setFilters={setFilters}
        filterValues={filterValues}
        isModalOpen={isMarketModalOpen}
        setIsModalOpen={setIsMarketModalOpen}
      />
      <LanguageModal
        setFilterField={setFilterField}
        setFilterDate={setFilterDate}
        setFilters={setFilters}
        filterValues={filterValues}
        isModalOpen={isLanguageModalOpen}
        setIsModalOpen={setIsLanguageModalOpen}
      />
      <DateModal
        setFilterField={setFilterField}
        setFilterDate={setFilterDate}
        setFilters={setFilters}
        filterValues={filterValues}
        isModalOpen={isDateModalOpen}
        setIsModalOpen={setIsDateModalOpen}
      />
      <StatusModal
        setFilterField={setFilterField}
        setFilterDate={setFilterDate}
        setFilters={setFilters}
        filterValues={filterValues}
        isModalOpen={isStatusModalOpen}
        setIsModalOpen={setIsStatusModalOpen}
      />
      <ThemeProvider theme={theme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => setIsMarketModalOpen(true)}>
                <div style={{ display: "flex", gap: "3px" }}>
                  Rynek
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </TableCell>
              <TableCell onClick={() => setIsLanguageModalOpen(true)}>
                <div style={{ display: "flex", gap: "3px" }}>
                  Język
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </TableCell>
              <TableCell></TableCell>
              <TableCell onClick={() => setIsDateModalOpen(true)}>
                <div style={{ display: "flex", gap: "3px" }}>
                  Data
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </TableCell>
              <TableCell onClick={() => setIsStatusModalOpen(true)}>
                <div style={{ display: "flex", gap: "3px" }}>
                  Status
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <InboxRow key={index} {...row} />
            ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </div>
  );
};

export default InboxTable;
